.dateAndTimePicker {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 220px;
}

.dateAndTimePicker label {
  color: #7e57c2;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.dateAndTimePicker input[type="date"],
.dateAndTimePicker input[type="time"] {
  width: 100%;
  max-width: 220px; /* Control the max-width of the input */
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #7e57c2;
  color: #7e57c2;
}

.dateAndTimePicker input[type="checkbox"] {
  margin-left: 10px;
}
