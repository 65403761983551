@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.trendsPage {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* centers items vertically in flex container */
  justify-content: center; /* centers items horizontally in flex container */
  background-color: #f0f0f0;
  min-height: 100vh;
  font-family: "Bubblegum Sans", cursive;
}

.trendsPage h2 {
  color: #7e57c2;
}

.trensPage .section {
  display: flex;
  flex-direction: row;
  justify-content: space-around; /* Added for evenly spaced buttons */
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
