@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Bubblegum Sans", cursive;
  color: #4d4d4d;
}

.homePage {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: radial-gradient(circle, #f0f0f0, #7e57c2); /* Add radial gradient */
}

.homePage-header {
  text-align: center;
}

.homePage-header h1 {
  color: #ffc043; /* Keep color orangeish */
  font-size: 2.5em;
  margin: 2rem 0;
  text-shadow: 2px 2px #999; /* Add text shadow for better visibility */
}
