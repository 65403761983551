@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.AllActivitiesPage {
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  min-height: 100vh;
  font-family: "Bubblegum Sans", cursive;
}

.activitiesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px; /* adjust this as needed */
}

.activityCard {
  width: 80%;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  color: white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.activityCard.reading {
  background-color: #7e57c2;
}

.activityCard.movement {
  background-color: #ff7043;
}

.weekHeader {
  color: #7e57c2;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteBtn {
  background: none;
  border: none;
  cursor: pointer;
}

.trashIcon {
  color: #ffffff; /* Normal state color, change as needed */
}

.deleteBtn:hover .trashIcon {
  color: #cccccc; /* Hover state color, change as needed */
}
