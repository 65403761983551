.success-message {
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
  position: fixed; /* changed from absolute to fixed */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 2em; /* increased font size */
  color: white; /* text color changed to white */
  z-index: 100; /* make sure it's above other content */
  background: rgba(110, 221, 97, 0.8); /* background changed to lightish green with 80% opacity */
  padding: 20px;
  border-radius: 10px;
}

.success-message.transition-entering {
  opacity: 0;
  visibility: visible;
}

.success-message.transition-entered {
  opacity: 1;
  visibility: visible;
}

.success-message.transition-exiting {
  opacity: 0;
}

.success-message.transition-exited {
  visibility: hidden;
}
