@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  background-color: #7e57c2;
  font-family: "Bubblegum Sans", cursive;
}

.header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu-icon {
  color: #fff;
  background: none;
  border: none;
  font-size: 24px; /* adjust size as needed */
  cursor: pointer;
  position: absolute;
  left: 10px; /* adjust as needed */
}

.current-page {
  color: #d1c4e9;
  font-size: 1.2em;
  text-transform: uppercase;
}

.menu {
  display: none;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.menu.show {
  display: flex;
}

.navigation a {
  color: #d1c4e9;
  text-decoration: none;
  font-size: 1.2em;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
  margin: 0.5em 0;
}

.navigation a:hover {
  color: #ffeb3b;
  text-decoration: underline;
}

.navigation .activeRoute {
  color: #ffeb3b;
  font-weight: bold;
}

@media (min-width: 769px) {
  .hide-on-large {
    display: none;
  }

  .navigation {
    flex-direction: row;
    justify-content: space-around;
  }

  .menu-icon {
    display: none;
  }

  .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-around; /* this should distribute the menu items evenly on large screens */
  }
}
