.activityInput {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  flex-wrap: wrap;
  margin: auto;
  max-width: 1200px; /* adjust as needed */
}

.activityInput div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 240px;
  margin: 10px 0; /* Add vertical margin */
}

.activityInput div .button-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row; /* align buttons horizontally */
}

.lively {
  color: #ffc043;
}
