@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.AddActivityPage {
  padding: 20px;
  background-color: #f0f0f0;
  min-height: 100vh;
  font-family: "Bubblegum Sans", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.section .lively-header {
  color: #ffc043;
  margin: 0px;
  padding: 0px;
}
