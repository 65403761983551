@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.weekPage {
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  min-height: 100vh;
  font-family: "Bubblegum Sans", cursive;
}

.chartsSection {
  display: flex;
  flex-direction: row; /* changed from column to row */
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  flex-wrap: wrap; /* wraps the items to the next line if there isn't enough space */
}
