.activityTypeGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.activityTypeButton {
  font-size: 1.2em;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #7e57c2;
  color: white;
  cursor: pointer;
  margin-top: 0.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  width: 220px;
}

.activityTypeButton:not(:disabled):hover {
  background-color: #ff7043;
  transform: scale(1.1);
}

.reading-button {
  background-color: #7e57c2;
}

.exercise-button {
  background-color: #ff7043;
}

.reading-button:not(:disabled):hover,
.exercise-button:not(:disabled):hover {
  background-color: #6edd61; /* Lightish green */
}

.activityTypeButton:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}
