@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: "Bubblegum Sans", cursive;
  background-color: #f0f0f0;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.spinner-text {
  margin-top: 20px;
  color: #7e57c2;
  font-size: 1.5em;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
