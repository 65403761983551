.time-button {
  margin: 10px;
  background-color: #7e57c2;
  color: #fff;
  border: none;
  border-radius: 50%; /* make buttons round */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  width: 70px; /* increased width */
  height: 70px; /* increased height */
  font-size: 14px; /* increased font size */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* center the text */
  padding: 5px; /* added some padding to ensure the text doesn't touch the edges */
}

.time-button:hover {
  background-color: #ff7043;
  transform: scale(1.1);
}
