.chart-placeholder {
  width: 500px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-placeholder p {
  font-size: 20px; /* Increase the size of the text */
  font-weight: bold; /* Make the text bold */
  color: #6c757d; /* Optional: a grey text color */
}

.chartContainer {
  flex: 1 0 auto;
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.highlighted {
  opacity: 1;
}

.notHighlighted {
  opacity: 0.6;
}
