.periodNavigation {
  display: flex;
  flex-direction: row;
  justify-content: center; /* This will center the buttons */
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
}

.periodNavigation button {
  background-color: #ffc043;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px; /* Adjust this as needed */
}

.periodNavigation button:disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

.periodNavigation button:not(:disabled):hover {
  background-color: #ff7043;
  transform: scale(1.1);
}
