.inputGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.label {
  color: #7e57c2;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

/* Hide the native controls */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Customize the number input field */
.inputField,
.slider {
  border: 1px solid #7e57c2;
  border-radius: 4px;
  padding: 10px;
  width: 220px;
  margin-bottom: 20px;
}

.durationInputField {
  font-size: 1.2rem;
  text-align: end;
  color: #7e57c2;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #7e57c2;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #7e57c2;
  cursor: pointer;
  border-radius: 50%;
}

.error {
  color: #ff7043;
  margin-bottom: 20px;
}
