@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.profilePage {
  padding: 20px;
  background-color: #f0f0f0;
  min-height: 100vh;
  font-family: "Bubblegum Sans", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.lively-header {
  color: #ffc043;
  margin-bottom: 10px;
}

.profilePage p {
  color: #7e57c2;
  width: 100%;
  font-size: 1.2rem;
  margin: 10px 0;
}

.section button {
  font-size: 1.2em;
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #7e57c2;
  color: white;
  cursor: pointer;
  margin-top: 0.5rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 220px;
}

.section select:hover,
.section button:hover {
  background-color: #ff7043;
  transform: scale(1.1);
}
