.login-button {
  font-size: 1.2em;
  padding: 10px 20px;
  border-radius: 4px; /* Slightly rounded corners */
  border: none;
  background-color: #7e57c2; /* Keep button color purple */
  color: white;
  cursor: pointer;
  margin-top: 2rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  text-align: center; /* center the text */
}

.login-button:hover {
  background-color: #ff7043;
  transform: scale(1.1);
}
