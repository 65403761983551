.weekHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.weekHeader h2 {
  margin: 0;
  font-size: 2.5em;
  text-align: center;
  color: #7e57c2;
}

.weekNavigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
}

.weekHeader small {
  font-size: 1.2em; /* adjust the font size as needed */
  text-align: center;
  color: #7e57c2;
}

.weekHeader button {
  background-color: #ffc043;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weekHeader .invisible {
  visibility: hidden;
}

.weekHeader button:hover {
  background-color: #ff7043;
  transform: scale(1.1);
}
