@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");

.statistics {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  max-width: 1200px; /* adjust as needed */
  margin: auto;
}

.statistics > div {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* if you want to align the children horizontally as well */
}

.lively {
  color: #ffc043;
}
